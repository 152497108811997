
import { defineComponent, ref, onMounted, onBeforeUnmount, computed, DefineComponent, Ref, watch } from "vue";
import KTMenu from "@/layout/header/Menu.vue";
import KTMenuDropdown from "@/layout/header/MenuDropdown.vue";
import { useStore } from "vuex";
import { useOpenAudioNotification } from "@/store/OpenAudioNotification";
import {
  // headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";
import ModalFiltro from "./ModalFiltro.vue";
import { Modal } from "bootstrap";
import AuthService from "@/services/AuthService";
import { useSocketStore } from '@/store/SocketPinia';
import { useRouter, useRoute } from "vue-router";


export default defineComponent({
  name: "KTHeader",
  components: {
    KTMenu,
    KTMenuDropdown,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isMobile = ref(false);
    const usuario = JSON.parse(AuthService.getUsuario())
    const socketStore = useSocketStore();
    const AudioNotification = useOpenAudioNotification();
    const eventName: Ref<string|null> = ref(null) 
    const showPermissionModal = ref(false);
    const playYes = ref(false);
    const ticketsWithMessages: Ref<string[]> = ref([])
    const sound:Ref<any> = ref(null);
    let socketInitialized = false; // Variável de controle


    function getStorage() {
      const storedData = localStorage.getItem('ticketsWithMessages');
      const soundEscolhido = localStorage.getItem('Sound');
      
      if (storedData) {
        const tickesUnread = JSON.parse(storedData);
        if (tickesUnread && tickesUnread.length) {
          ticketsWithMessages.value = tickesUnread;
        }
      }

      if (soundEscolhido) {
        sound.value = new Audio(soundEscolhido);
      } else {
        sound.value = new Audio('sound/bereal.mp3')
      }
    }

    function grantPermission() {
      ativaEventoSocket()
      getStorage()
      playYes.value =true
      sound.value.play().then(() => {
        sound.value.pause(); 
        sound.value.currentTime = 0; 
      }).catch((error) => {
        console.log('Erro ao habilitar som:', error);
      });
      showPermissionModal.value = false;
    }

    function denyPermission() {
      playYes.value = false
      showPermissionModal.value = false;
    }
    
    function playSound() {
      getStorage()
      if(!playYes.value) return;
      sound.value.play().catch((error) => {
        console.log('Erro ao tocar som:', error);
      });
    }
    function ativaEventoSocket(){
      if (!socketInitialized) {  // Evita múltiplas inicializações
        socketStore.startSocket();
        listeningEvent();
        socketInitialized = true; // Marca como inicializado
      }
    }
    
    function listeningEvent(){
      let ativaSom = 0;
      eventName.value = `${process.env.VUE_APP_ENV}-commentReceived-${localStorage.getItem('id_project') || '1'}`
      socketStore.newListen({
        name: eventName.value,
        callbackFunction: (data) => {
          if (!ticketsWithMessages.value.includes(data)) {
            if(!(router.currentRoute.value.path === '/tickets/'+data)){
              ticketsWithMessages.value.push(data);
              setStorage('ticketsWithMessages', ticketsWithMessages.value);
              ativaSom = 1;
            }
          }
          if(ativaSom == 1){
            playSound();
          }
        },
      });
    }

    watch(() => AudioNotification.status, (newStatus) => {
      grantPermission();
    });

    watch(()=> route.path, 
          ()=>{
            ticketsWithMessages.value = ticketsWithMessages.value.filter((tck)=>String(tck) !== route.params.id)
            setStorage('ticketsWithMessages', ticketsWithMessages.value)
          }
    )

    function setStorage(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    }


    onMounted(() => {
      ativaEventoSocket();
      const permission = localStorage.getItem('soundPermission');
      getStorage()
      if (permission === 'denied' || !permission) showPermissionModal.value = true;
      let userAgent = navigator.userAgent || navigator.vendor;
      if (userAgent.match(/Android/i) || userAgent.match(/iPhone/i)) {
        isMobile.value = true;
      } else {
        isMobile.value = false;
      }
    });

    onBeforeUnmount(() => {
      if (socketInitialized) {
        socketStore.disconnectSocket(); // Método para parar o WebSocket
        socketInitialized = false; // Redefine o estado
      }
    });

    return {
      headerTitle: "CAMP Tickets",
      // headerWidthFluid,
      headerLeft,
      asideDisplay,
      isMobile,
      usuario,
      playSound,
      showPermissionModal,
      grantPermission,
      denyPermission,
      ticketsWithMessages,
      router
    };
  },
});
